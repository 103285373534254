<template>
    <svg :height="fullHeight" :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">

        <!-- Base color -->
        <rect :width="fullWidth" :height="fullHeight" :style="baseStyle"/>

        <!-- Inner colored field for mon -->
        <rect v-if="isMon" x="0" :y="fullHeight / 3" :width="fullWidth" :height="fullHeight / 3" :style="monStyle"/>

        <!-- Yellow stripes for 1-5 dan -->
        <rect v-if="yellowStripes > 0" :x="stripeWidth" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>
        <rect v-if="yellowStripes > 1" :x="stripeWidth * 3" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>
        <rect v-if="yellowStripes > 2" :x="stripeWidth * 5" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>
        <rect v-if="yellowStripes > 3" :x="stripeWidth * 7" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>
        <rect v-if="yellowStripes > 4" :x="stripeWidth * 9" y="0" :width="stripeWidth" :height="fullHeight" :style="yellowFill"/>

        <!-- Red fields for 6-7 dan -->
        <rect v-if="newrank && (newrank == '6 dan' || newrank == '7 dan') || oldrank >= 16 && oldrank <= 17" :x="fullWidth / 5" :width="fullWidth/5" :height="fullHeight" :style="redFill"/>
        <rect v-if="newrank && (newrank == '6 dan' || newrank == '7 dan') || oldrank >= 16 && oldrank <= 17" :x="fullWidth * 3 / 5" :width="fullWidth/5" :height="fullHeight" :style="redFill"/>

        <!-- Number for 6-10 dan -->
        <text v-if="yellowNumber" :x="5" y="15" fill="yellow">{{ yellowNumber }}</text>
    </svg>
</template>

<script>
 export default {
     props: {
         rank: [Number, String]
     },
     data() {
         return {
             fullWidth: 100,
             fullHeight: 20,
             stripeWidth: 5,
             yellow: 'yellow',
             orange: 'orange',
             green: 'green',
             blue: 'blue',
             brown: 'rgb(109,65,47)',
             red: 'rgb(182,0,0)'
         }
     },
     computed: {
       oldrank() {
           return isNaN(this.rank) ? undefined : this.rank
       },
       newrank() {
         return isNaN(this.rank) ? this.rank : undefined
       },
       isMon() {
           return this.newrank && this.newrank.includes('mon') || this.oldrank > 0 && this.oldrank < 6
       },
       yellowStripes() {
         if (this.newrank) {
           if (this.newrank.includes('dan')) {
             let dans = this.newrank.match(/^\d+/).map(Number)[0]
             return dans > 5 ? 0 : dans;
           }
           return 0
         } else {
             return this.oldrank < 16 ? this.oldrank - 10 : 0
         }
       },
       yellowNumber() {
         if (this.newrank) {
           if (this.newrank.includes('dan')) {
             let dans = this.newrank.match(/^\d+/).map(Number)[0]
             return dans > 5 ? dans : 0;
           }
           return 0
         } else {
             return this.oldrank >= 16 ? this.oldrank - 10 : 0
         }
       },
       viewBox() {
         return `0 0 ${this.fullWidth} ${this.fullHeight}`
       },
       baseStyle() {
         return this.styleWithFill(this.baseColor)
       },
       monStyle() {
         return this.styleWithFill(this.monColor)
       },
       yellowFill() {
         return this.styleWithFill(this.yellow)
       },
       redFill() {
         return this.styleWithFill(this.red)
       },
             baseColor() {
                 let oldrank = this.oldrank || this.newrank
                 switch(oldrank) {
                     case 6: return this.yellow
                     case 7: return this.orange
                     case 8: return this.green
                     case 9: return this.blue
                     case 10: return this.brown
                     case 11:
                     case 12:
                     case 13:
                     case 14:
                     case 15:
                     case 16:
                     case 17:
                         return 'black'
                     case 18:
                     case 19:
                     case 20:
                         return this.red
                     case '5 mon':
                     case '5 kyu':
                         return this.yellow
                     case '3 mon':
                     case '4 kyu':
                         return this.orange
                     case '1 mon':
                     case '3 kyu':
                         return this.green
                     case '2 kyu': return this.blue
                     case '1 kyu': return this.brown
                     case '1 dan':
                     case '2 dan':
                     case '3 dan':
                     case '4 dan':
                     case '5 dan':
                     case '6 dan':
                     case '7 dan':
                         return 'black'
                     case '8 dan':
                     case '9 dan':
                     case '10 dan':
                         return this.red
                     default: return 'white'
                 }
             },
             monColor() {
                 let oldrank = this.oldrank || this.newrank
                 switch(oldrank) {
                     case 1: return this.yellow
                     case 2: return this.orange
                     case 3: return this.green
                     case 4: return this.blue
                     case 5: return this.brown
                     case '6 mon': return this.yellow
                     case '4 mon': return this.orange
                     case '2 mon': return this.green
                     default: return 'white'
                 }
             }
         },
         methods: {
             styleWithFill(color) {
                 return `fill:${color};stroke-width:1;stroke:black`
             }
         }
     }
</script>

/**
Old oldrank will internally be represented by a number.
| value | oldrank name | color       | japanese name |
|-------+-----------+-------------+---------------|
|     0 | No oldrank   | vitt        | rokkyu        |
|     1 | 5 mon     | gul-vitt    |               |
|     2 | 4 mon     | orange-vitt |               |
|     3 | 3 mon     | grön-vitt   |               |
|     4 | 2 mon     | blå-vitt    |               |
|     5 | 1 mon     | brun-vitt   |               |
|     6 | 5 kyu     | gult        | gokyu         |
|     7 | 4 kyu     | orange      | yonkyu        |
|     8 | 3 kyu     | grönt       | sankyu        |
|     9 | 2 kyu     | blått       | nikyu         |
|    10 | 1 kyu     | brunt       | ikkyu         |
|    11 | 1 dan     | svart       | Shodan        |
|    12 | 2 dan     | svart       | Nidan         |
|    13 | 3 dan     | svart       | Sandan        |
|    14 | 4 dan     | svart       | Yondan        |
|    15 | 5 dan     | svart       | Godan         |
|    16 | 6 dan     | röd-svart   | Rokudan       |
|    17 | 7 dan     | röd-svart   | Shichidan     |
|    18 | 8 dan     | röd-svart   | Hachidan      |
|    19 | 9 dan     | rött        | Kudan         |
|    20 | 10 dan    | rött        | Judan         |

New oldrank will internally be represented by a string value.
| value     | color       | japanese name |
|-----------+-------------+---------------|
|           | vitt        |               |
| 6 mon     | vit-gult    |               |
| 5 mon     | gul-vitt    |               |
| 4 mon     | vit-orange  |               |
| 3 mon     | orange-vitt |               |
| 2 mon     | vit-grönt   |               |
| 1 mon     | grön-vitt   |               |
| 5 kyu     | gult        | gokyu         |
| 4 kyu     | orange      | yonkyu        |
| 3 kyu     | grönt       | sankyu        |
| 2 kyu     | blått       | nikyu         |
| 1 kyu     | brunt       | ikkyu         |
| 1 dan     | svart       | Shodan        |
| 2 dan     | svart       | Nidan         |
| 3 dan     | svart       | Sandan        |
| 4 dan     | svart       | Yondan        |
| 5 dan     | svart       | Godan         |
| 6 dan     | röd-svart   | Rokudan       |
| 7 dan     | röd-svart   | Shichidan     |
| 8 dan     | röd-svart   | Hachidan      |
| 9 dan     | rött        | Kudan         |
| 10 dan    | rött        | Judan         |
*/
